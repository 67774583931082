@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/media-queries.scss';

$height: 404px;

.DashboardActiveGatewaysLoadingTemplate {
  &__wrapper {
    background-color: $color-background;
    min-height: $height;
    border-radius: 8px;
    padding: $spacing-m;
    margin-top: $spacing-m;
    display: flex;
    flex-direction: row;
    gap: $spacing-m;
  }

  &__data {
    width: 33.33%;

    @include medium-devices {
      width: 100%;
    }
  }

  &__data-header {
    width: 117px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__data-header-skeleton {
    height: 50px !important;
  }

  &__data-content {
    width: 100%;
    margin-top: $spacing-m;
    border-radius: 8px;
    overflow: hidden;
  }

  &__skeleton {
    width: 100% !important;
    height: calc($height - 50px - ($spacing-m * 3)) !important;
  }

  &__chart {
    width: 66.66%;

    @include medium-devices {
      width: 100%;
    }
  }

  &__chart-header {
    width: 117px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__chart-header-skeleton {
    height: 50px !important;
  }

  &__chart-content {
    margin-top: $spacing-m;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
}
